import { useState, useRef,useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import "../custom.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

export default function CreateQuote() {

    const navigate = useNavigate()
    //const [email, setEmail] = useState('')
    //const [fname, setFName] = useState('')
    //const [lname, setLName] = useState('')
    //const [phone, setPhone] = useState('')
    //const [gender, setGender] = useState('')
    const [destinationCity, setDestinationCity] = useState('')
   // const [custType, setCustType] = useState('')
   
    //const [assign, setAssign] = useState('')

    const [packageId, setPackageId] = useState(0)
    const [packagename, setPackageName] = useState('')
    const [leadpassenger, setLeadPassenger] = useState('')
    const [travelSdate, setTravelSdate] = useState('')
    const [travelEdate, setTravelEdate] = useState('')
    const [departureCity, setDepartureCity] = useState('')
    const [currency, setCurrency] = useState('')
    const [holidayPackageId, setHolidayPackageId] = useState(0)

    const [hotelname, setHotelName] = useState('')
    const [roomtype, setRoomType] = useState('')
    const [place, setPlace] = useState('')
    const [checkindate, setCheckindate] = useState('')
    const [numroom, setNumRoom] = useState('')
    const [numnights, setNumNights] = useState('')
    const [specialinst, setSpecialInst] = useState('')
    const [mealplan, setMealPlan] = useState('')

    const [packageInclusion, setPackageInclusion] = useState('')
    const [packageExclusion, setPackageExclusion] = useState('')

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [dayimages, setDayImages] = useState('')


    const [startDate, setStartDate] = useState("");
   

    const [packages, setPackages] = useState([]);
   
    let { id } = useParams();
    const [booknum, setBookNum] = useState('')

    const [packageCost, setPackageCost] = useState('')
    const [taxable, setTaxable] = useState('')
    const [gst, setGst] = useState('')
    const [gstper, setGstPer] = useState('')
    const [totalTax, setTotalTax] = useState('')
    const [finalCost, setFinalCost] = useState('')
    const [markupPrice, setMarkupPrice] = useState('')
    const [markupPer, setMarkupPer] = useState('')
    const [tcsPrice, setTcsPrice] = useState('')
    const [tcsPer, setTcsPer] = useState('')

    const [noAdult, setAdult] = useState('')
    const [noChild, setChild] = useState('')
    const [noInfant, setInfant] = useState('') 
    const [paymentStatusId, setPaymentStatusId] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    
    
    //const [customer, setCustomer] = useState('') 
    //const [staff, setStaff] = useState('') 
    //const [customerType, setCustomerType] = useState('') 
   // const [leadStatusId, setLeadStatusId] = useState('') 
   // const [statusId, setStatusId] = useState('')    

    
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [users, setusers] = useState([]);
    const [filtered, setfiltered] = useState([]);
    const [search, setsearch] = useState("");

    let roleId = sessionStorage.getItem('roleId');
    let userId = sessionStorage.getItem('userId');


    const [itinerary, setItinerary] = useState();
    //let assignId = userId;

    const [tempDay, setTempDay] = useState("");
    
    const enddateRef = useRef();
    const packageCostRef = useRef();
    const taxableRef = useRef();
    const gstperRef = useRef();
    const gstRef = useRef();
    const totalTaxRef = useRef();
    const finalCostRef = useRef();
    const markupPriceRef = useRef();
    const markupPerRef = useRef();
    const tcsPriceRef = useRef();
    const tcsPerRef = useRef();

    const [allHotels, setAllHotels] = useState([
        {
            hotelDetailId: 0, packageDetailId: packageId, hotelName: "", checkInDt: "", checkOutDt: "", roomType: "",
            place: "", noOfRooms: "",  specialInstructions: "", mealPlan: ""
        }
    ]);

    const handleAddHotels = () => {
        console.log('Hotel--', allHotels)
        const values = [...allHotels];
        values.push({
            hotelName: "", checkInDt: "",
            checkOutDt: "", hotelDetailId: 0, packageDetailId: packageId,
            roomType: "", place: "",
            noOfRooms: "", 
            specialInstructions: "", mealPlan: ""
        },);
        setAllHotels(values);
    };

    const hotelInputChange = (index, event, name) => {
        console.log('EV--',event)
        const values = [...allHotels];
        const updatedValue = name;
        values[index][updatedValue] = event.target.value;

        setAllHotels(values);
    };

    const hotelInputChangeDate = (index, value, name) => {
        console.log('EVDa--', value)
        const values = [...allHotels];
        const updatedValue = name;
        values[index][updatedValue] = value;

        setAllHotels(values);
    };
    
    
    
    const hotelRemove = (index, htDid=0) => {
        if (window.confirm('Delete this Hotel?' )) {
            const values = [...allHotels];
            if (htDid > 0) { deleteHotel(htDid) }            
            values.splice(index, 1);
            setAllHotels(values);
        }
    };

    function deleteHotel(htDid) {

        var response = APICall(null, 'HotelDetails/DeleteHotelDetail?hotelDetailId=' + htDid, 'DELETE')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {                   
                    setMessage(resposnse.Message);
                } else {
                    setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }

            });

    }



    useEffect(() => {

       
        if (!id || id === null ) {
            id = 0;
            setMessage('Invalid quote Id!');
            setError(400);
        } else {


            var response = APICall(null, 'BookingMasters/GetBookingMasterViewById?bookingMasterId=' + id, 'GET')
                .then(resposnse => {

                    if (resposnse.StatusCode === 200) {

                        // setfiltered(resposnse.Result);
                        setBookNum(resposnse.Result.bookingNo);
                        if (resposnse.Result.packageDetail != null && resposnse.Result.packageDetail.packageName != null ) {
                            setPackageName(resposnse.Result.packageDetail.packageName);
                            searchRef.current.value = resposnse.Result.packageDetail.packageName
                            console.log('PKGN=', resposnse.Result.packageDetail.packageName)
                        }
                        if (resposnse.Result.packageDetail != null && resposnse.Result.packageDetail.leadPassengerName != null) {
                            setLeadPassenger(resposnse.Result.packageDetail.leadPassengerName);
                        } else {
                            setLeadPassenger(resposnse.Result.customerUser);
                        }
                        setLeadPassenger(resposnse.Result.packageDetail.leadPassengerName);
                        setPackageId(resposnse.Result.packageDetail.packageDetailId);
                        setTravelSdate(new Date(resposnse.Result.packageDetail.travelStartDt));
                        setTravelEdate(new Date(resposnse.Result.packageDetail.travelEndDt));
                        setDepartureCity(resposnse.Result.packageDetail.departureCity);
                        setCurrency(resposnse.Result.packageDetail.currency);
                        setHolidayPackageId(resposnse.Result.packageDetail.holidayPackageId)
                        setPackageInclusion(resposnse.Result.packageDetail.packageInclusion)
                        setPackageExclusion(resposnse.Result.packageDetail.packageExclusion);
                       
                        if (resposnse.Result.itineraryDetails != null && resposnse.Result.itineraryDetails.length > 0) {
                            setItinerary(resposnse.Result.itineraryDetails)
                        }
                        if (resposnse.Result.hotelDetails != null && resposnse.Result.hotelDetails.length > 0) {
                            setAllHotels(resposnse.Result.hotelDetails)
                        }
                            

                        setPackageCost(resposnse.Result.packageDetail.packageCost);
                        setTaxable(resposnse.Result.packageDetail.taxable);
                        setGst(resposnse.Result.packageDetail.gst);
                        setGstPer(resposnse.Result.packageDetail.gstpercent);
                        setTotalTax(resposnse.Result.packageDetail.totalTax);
                        setFinalCost(resposnse.Result.packageDetail.finalCost);
                        setPaymentStatusId(resposnse.Result.paymentStatusId);
                        setPaymentStatus(resposnse.Result.paymentStatus);
                        setMarkupPer(resposnse.Result.packageDetail.markupPercent)
                        setMarkupPrice(resposnse.Result.packageDetail.markup)
                        setTcsPrice(resposnse.Result.packageDetail.tcs)
                        setTcsPer(resposnse.Result.packageDetail.tcspercent)
                        setAdult(resposnse.Result.packageDetail.noOfAdult)
                        setChild(resposnse.Result.packageDetail.noOfChild)
                        setInfant(resposnse.Result.packageDetail.noOfInfant)
                    } else {
                        setMessage(resposnse.Message);
                        setError(400);
                    }
                    console.log('Data', resposnse);
                });


            


        }
              


    }, []);

    const searchRef = useRef();
    const titleRef = useRef([]);
   

    useEffect(() => {

        if (!id || id === null) {
            id = 0;
            setMessage('Invalid quote Id!');
            setError(400);
        } else {


            if (search != null && search?.length > 2) {
                console.log(search)
                const packageData = {

                    keyword: search,
                };
                var response = APICall(packageData, 'HolidayPackages/GetByCondition', 'POST')
                    .then(resposnse => {
                        // console.log('Data', resposnse);
                        if (resposnse.StatusCode === 200) {
                            setfiltered(resposnse.Result);
                        } else {
                            setMessage(resposnse.Message);
                            setError(resposnse.StatusCode);
                        }
                        console.log('Data', resposnse);
                    });
            }


        }
    }, [search]);

    //useEffect(() => {

    //    setItin(values);
    //}, [])
    let itValues = [];
    var promises = []; 
    function createItinerary(eDate) {
        console.log('createItinerary=', eDate )
       
        const timeDifference = Math.abs(eDate - travelSdate);
        const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
       

        itValues = [] 
        let pckArray = []
        //setItin(values); 
        
        
        for (let j = 0; j <= daysRemaining; j++) {

            var promise = getPackage(holidayPackageId, (j + 1))
            console.log('promise=', promise)
            promises.push(promise); 
            
            //itValues.push({
            //    title: pckArray['title'],
            //    details: pckArray['details'],
            //    packageDayImages: pckArray['packageDayImages'],
                
            //});
        }

        Promise.all(promises).then(function () {
            console.log("DISPLAY your Message here", promises); // display here
            itValues = promises;
            localStorage.setItem('itinery', promises);
            setItinerary(itValues);
            console.log('Values123=', itValues)
           
        });

        
        console.log('Values=', itValues)

    }

    function getPackage(pkId, dayno) {

        let resArray = [];
         
        

        var response = APICall(null, 'HolidayPackages/GetHolidayPackageDayByDayNo?holidayPackageId=' + pkId + '&dayNo=' + dayno, 'GET')
            .then(response => {
               
                if (response.StatusCode === 200) {
                    
                    let dimage = response.Result.holidayPackageDayDocuments;
                    let listItems = '';

                    for (let j = 0; j < dimage.length; j++) {
                        listItems = listItems + 'https://crm.vacationum.com/img/' + dimage[j].documentName + '\n'
                    }
                    resArray['title'] = response.Result.heading
                    resArray['details'] = response.Result.content
                    resArray['packageDayImages'] = listItems
                    resArray['itineraryDetailId'] = 0
                    resArray['packageDetailId'] = packageId
                    resArray['dayNo'] = dayno
                   
                    

                } else {
                    resArray['title'] = ''
                    resArray['details'] = ''
                    resArray['packageDayImages'] = ''
                    resArray['itineraryDetailId'] = 0
                    resArray['packageDetailId'] = packageId
                    resArray['dayNo'] = dayno
                    //setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }
                
            });

        return resArray;
    }

    const itineraryInputChange = (index, name,value) => {
        const values = [...itinerary]; console.log('E-S-', value)
        const updatedValue = name;
        values[index][updatedValue] = value; 

        setItinerary(values); console.log('E-E-', values)
    };

    const handleUpdate = () => {
        const values = [...itinerary];
        setItinerary(values);
        //createItinerary(travelEdate);
    };

    const handleRemovePlayers = (index, itDid = 0) => {
        if (window.confirm('Delete this Itinerary?')) {
            const values = [...itinerary];
            values.splice(index, 1);
            if (itDid > 0) { deleteHandler(itDid) }
            //titleRef.current[index].value = ''
            // console.log('titleRef=', titleRef.current[index])
            setItinerary(values);
            console.log('Values1=', values)
        }
    };

    function deleteHandler(itDid) {

            var response = APICall(null, 'ItineraryDetails/DeleteItineraryDetail?itineraryDetailId=' + itDid, 'DELETE')
                .then(resposnse => {
                    console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        //viewbooking();
                        // setQuote(resposnse.Result);
                        setMessage(resposnse.Message);
                    } else {
                        setMessage(resposnse.Message);
                        //setError(resposnse.StatusCode);
                    }
                    
                });
        
    }

    function setEndDate(date) {
        console.log('Days=', parseInt(tempDay) > 0)
        //if (tempDay != null && tempDay) {
        //    let date1 = new Date(date);
        //    console.log('date=', date1)
        //    date1 = date1.setDate(date1.getDate() + parseInt(tempDay));
        //    console.log('Newdate=', new Date(date1))
        //    ////let format2 = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
        //    enddateRef.current.value = new Date(date1);
        //    setTravelEdate(new Date(date1))
        //}

    }

    

    const packageInclusionText = (content) => {
        setPackageInclusion(content)

    }

    const handleChange2 = (content) => {
        setPackageExclusion(content)

    }

    function clearPackage() {

        setPackageName(null);
        searchRef.current.value = '';
        setsearch('');
        setItinerary('')

    }

  
   
     const paymentChange = (event) => {


            console.log(packageCost + ' - ' + taxable + ' = ' + gst);
            let calculateMP = 0;
            calculateMP = (parseFloat(markupPer) * parseFloat(packageCost)) / 100




            let calculateTCS = 0;
            calculateTCS = (parseFloat(tcsPer) * (parseFloat(packageCost) + parseFloat(calculateMP))) / 100
            setTcsPrice(parseFloat(calculateTCS));


            let gstTemp = 0;
            gstTemp = (parseFloat(gstper) * (parseFloat(calculateMP) + parseFloat(packageCost))) / 100;
            setGst(gstTemp);

            let finalamount = parseFloat(taxable) + parseFloat(gstTemp) + parseFloat(calculateTCS);
            let taxamount = parseFloat(gstTemp) + parseFloat(calculateTCS);

            setMarkupPrice(parseFloat(calculateMP));
            setTaxable(parseFloat(calculateMP) + parseFloat(packageCost));
            setMarkupPer(markupPer)
            setTotalTax(taxamount);
            setFinalCost(finalamount);

            tcsPriceRef.current.value = parseFloat(calculateTCS);
            gstRef.current.value = parseFloat(gstTemp);
            totalTaxRef.current.value = taxamount;
            finalCostRef.current.value = finalamount;
       

    }

    function loadCustomer(item) {
        setPackageName(item.packageMainHeading);
        setHolidayPackageId(item.holidayPackageId)
        const substringToRemove = / Nights/gi;
        const newString = item.duration.replace(substringToRemove, '');
        setTempDay(newString)
        setPackageInclusion(item.inclusionsContent)
        setPackageExclusion(item.exclusionsContent)
        console.log('Item=', item)
        // setPhone(customer.phone);
        // setGender(customer.gender);
        // setCustomer(customer.userId);
    }
    


    const submitHandler = (event) => {
        event.preventDefault()
       createQuoteRequest()
        
    }


    function createQuoteRequest() {

        console.log('Hotels', allHotels)
        console.log('Itin', itinerary)
        let newItinerary = []
        let newHotels = []


        for (let ii = 0; ii < itinerary.length;ii++) {
           
            newItinerary.push({
                title: itinerary[ii]['title'], details: itinerary[ii]['details'], dayNo: itinerary[ii]['dayNo'],
                packageDayImages: itinerary[ii]['packageDayImages'], itineraryDetailId: itinerary[ii]['itineraryDetailId'],
                packageDetailId: itinerary[ii]['packageDetailId']
            })
            
        }

        for (let ii = 0; ii < allHotels.length; ii++) {

            let neckin = allHotels[ii]['checkInDt']
            //neckin = neckin.toString().replace(' GMT+0530 (India Standard Time)', 'Z')
            //neckin = new Date(neckin)
            neckin = format(new Date(neckin), "yyyy-MM-dd 00:00:00")
            neckin = neckin.toString().replace(' ', 'T')


            let neckout = allHotels[ii]['checkOutDt']
            //neckout = neckout.toString().replace(' GMT+0530 (India Standard Time)', 'Z')
            //neckout = new Date(neckout)
            neckout = format(new Date(neckout), "yyyy-MM-dd 00:00:00")
            neckout = neckout.toString().replace(' ', 'T')

            //console.log('neckout', neckout)
           
            newHotels.push({
                checkInDt: neckin, checkOutDt: neckout, hotelDetailId: allHotels[ii]['hotelDetailId'], hotelName: allHotels[ii]['hotelName'],
                mealPlan: allHotels[ii]['mealPlan'], 
                noOfRooms: allHotels[ii]['noOfRooms'], packageDetailId: allHotels[ii]['packageDetailId'], place: allHotels[ii]['place'],
                roomType: allHotels[ii]['roomType'],  specialInstructions: allHotels[ii]['specialInstructions']
            })
        }

        let newTravelSdate = travelSdate
        //newTravelSdate = newTravelSdate.toString().replace(' GMT+0530 (India Standard Time)', 'Z')
        //newTravelSdate = new Date(newTravelSdate)
        newTravelSdate = format(new Date(newTravelSdate), "yyyy-MM-dd 00:00:00")
        newTravelSdate = newTravelSdate.toString().replace(' ', 'T')

        let newTravelEdate = travelEdate
        //newTravelEdate = newTravelEdate.toString().replace(' GMT+0530 (India Standard Time)', 'Z')
        //newTravelEdate = new Date(newTravelEdate)
        newTravelEdate = format(new Date(newTravelEdate), "yyyy-MM-dd 00:00:00")
        newTravelEdate = newTravelEdate.toString().replace(' ', 'T')

        

        const quoteData1 = {

            packageName: packagename,
            leadPassengerName: leadpassenger,
            travelStartDt: newTravelSdate,
            travelEndDt: newTravelEdate,
            currency: currency,
            departureCity: departureCity,
            bookingNo: booknum,
            bookingMasterId: id,
            note: '',
            socialMedia: '',
            packageDetailId: packageId,
            holidayPackageId: holidayPackageId,
            packageInclusion: packageInclusion,
            packageExclusion: packageExclusion,

            bookingMasterId: id,
            packageCost: packageCost,
            taxable: taxable,
            gst: gst,
            totalTax: totalTax,
            finalCost: finalCost,
            paymentStatusId: paymentStatusId,
            markup: markupPrice,
            markupPercent: markupPer,
            gstpercent: gstper,
            noOfAdult: noAdult,
            noOfChild: noChild,
            noOfInfant: noInfant,
            tcspercent: tcsPer,
            tcs: tcsPrice,
           
            itineraryDetails: newItinerary,
            hotelDetails: newHotels
           
        };
       

        var response = APICall(quoteData1, 'PackageDetails/CreateUpdatePackage', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);  
                if (resposnse.StatusCode === 201) {
                    localStorage.setItem('cmsg', JSON.stringify('Quote created successfully'));
                    navigate('/quotes');
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
            });

        console.log('All Obkect', quoteData1)
        //navigate('/dashboard');
        

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes - Holiday Package
                        </h3>

                    </div>

                    {(id != null && id>0)?
                        <>


                            <form class="forms-sample" onSubmit={submitHandler}>



                                {/*Step One*/}

                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title">Package Information -  {booknum}</h4>
                                            {id != null ?
                                                <>
                                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>



                                                    <p class="card-description"> </p>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Package</label>
                                                        <div className="col-sm-9">
                                                            {packagename ? <button type="button" class="btn btn-gradient-primary me-2" onClick={(e) => {
                                                                clearPackage();
                                                            }}>Clear</button> : ''}<input autocomplete="off" className="form-control"
                                                                type="text"
                                                                id="search"
                                                                placeholder="Search for..."
                                                                onChange={(e) => setsearch(e.target.value)}
                                                                ref={searchRef} style={{ width: 500 }} required
                                                            />
                                                            {search.length > 0 && (
                                                                <div className="dropdownsearch">
                                                                    {filtered.length > 0 ? (
                                                                        filtered.map((item, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="card"
                                                                                    key={index}
                                                                                    onClick={(e) => {
                                                                                        searchRef.current.value = item.packageMainHeading;
                                                                                        setsearch(""); loadCustomer(item);
                                                                                    }}>
                                                                                    <p>{item.packageMainHeading} </p>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <p>no match</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Package Name</label>
                                                        <div class="col-sm-9">
                                                            {packagename}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Lead Passenger Name </label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" id="exampleInputLPname" placeholder="Lead Passenger Name" defaultValue={leadpassenger} onChange={(e) => setLeadPassenger(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel Start Date</label>
                                                        <div class="col-sm-9">
                                                            <DatePicker showIcon utcOffset={0}
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={travelSdate}
                                                                onChange={(date) => { setTravelSdate(date); setEndDate(date) }}
                                                                required
                                                            />
                                                        </div>


                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel End Date</label>
                                                        <div class="col-sm-9"> <DatePicker showIcon utcOffset={0}
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={travelEdate}
                                                            onChange={(date) => { setTravelEdate(date); createItinerary(date) }}
                                                            required ref={enddateRef}
                                                        /> </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label"></label>
                                                        <div class="col-sm-9">  </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure City</label>
                                                        <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputDcity" placeholder="Departure City" defaultValue={departureCity} onChange={(e) => setDepartureCity(e.target.value)} required /> </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Currency</label>
                                                        <div class="col-sm-9"> {currency} <br /><select class="form-control" id="exampleSelectCurrency" value={currency} onChange={(e) => setCurrency(e.target.value)} required>
                                                            <option value="">Select</option>
                                                            <option value="INR">Rupee</option>
                                                            <option value="USD">US Dollar</option>
                                                            <option value="BP">British Pound</option>
                                                        </select> </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Package Inclusion</label>
                                                        <div class="col-sm-9">

                                                            <SunEditor setOptions={{
                                                                height: 200,
                                                                showPathLabel: false,
                                                                charCounter: true,
                                                                maxCharCount: 3000,
                                                                maxWidth: '700px',
                                                                width: 'auto',
                                                                height: 'auto',
                                                                minHeight: '100px',
                                                                maxHeight: '270px',
                                                                buttonList: [
                                                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    '/', //Line break
                                                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                                ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                                // plugins: [font] set plugins, all plugins are set by default
                                                                // Other option
                                                            }} onChange={packageInclusionText} setContents={packageInclusion} />
                                                            {/*<textarea class="form-control" id="examplePackageInclusion" rows="4" onChange={(e) => setPackageInclusion(e.target.value)} >{packageInclusion}</textarea>*/}

                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Package Exclusion</label>
                                                        <div class="col-sm-9">
                                                            <SunEditor setOptions={{
                                                                height: 200,
                                                                showPathLabel: false,
                                                                charCounter: true,
                                                                maxCharCount: 3000,
                                                                maxWidth: '700px',
                                                                width: 'auto',
                                                                height: 'auto',
                                                                minHeight: '100px',
                                                                maxHeight: '270px',
                                                                buttonList: [
                                                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    '/', //Line break
                                                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                                ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                                // plugins: [font] set plugins, all plugins are set by default
                                                                // Other option
                                                            }} onChange={handleChange2} setContents={packageExclusion} />

                                                            {/*<textarea class="form-control" id="examplepackageExclusion" rows="4" onChange={(e) => setPackageExclusion(e.target.value)} >{packageExclusion}</textarea>*/}

                                                        </div>
                                                    </div>

                                                    <div class="form-check form-check-flat form-check-primary">
                                                        <label class="form-check-label" />

                                                    </div>

                                                    {/*<button type="submit" class="btn btn-gradient-primary me-2">Next</button>*/}
                                                    <button type="button"
                                                        onClick={() => handleUpdate()}
                                                        class="btn btn-gradient-primary me-2" >Update</button>

                                                </>
                                                :
                                                <div class="form-check form-check-flat form-check-primary text-danger">

                                                    {message ? <p>{message}</p> : null}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/*Step One*/}

                                {/*Step Two*/}

                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title">Itinerary Information -  {booknum}</h4>
                                            {itinerary != null &&
                                                itinerary?.length > 0 ? itinerary.map((itininfo, index) => (
                                                <>
                                                    <div key={index}>
                                                        <div class="form-group row">
                                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Day</label>
                                                            <div className="col-sm-9"> {index + 1} </div>

                                                        </div>

                                                        <div class="form-group row">

                                                            <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Title</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" ref={(element) => titleRef.current[index] = element}
                                                                        class="form-control" id="exampleInputTitle" placeholder="Title" name="Title" defaultValue={itininfo.title} onChange={(event) =>
                                                                            itineraryInputChange(index, 'title', event.target.value)} required />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Description </label>
                                                            <div class="col-sm-9">
                                                                <SunEditor name="Description" setOptions={{
                                                                    height: 200,
                                                                    showPathLabel: false,
                                                                    charCounter: true,
                                                                    maxCharCount: 3000,
                                                                    maxWidth: '700px',
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    minHeight: '100px',
                                                                    maxHeight: '270px',
                                                                    buttonList: [
                                                                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                        '/', //Line break
                                                                        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                        ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                                    ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                                    // plugins: [font] set plugins, all plugins are set by default
                                                                    // Other option
                                                                    }} onChange={(content) =>
                                                                        itineraryInputChange(index, 'details', content)} setContents={itininfo.details} />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Day Images</label>
                                                                <div class="col-sm-9"> <textarea class="form-control" style={{ height: '80px' }} id="dayimages" placeholder="Day Images" defaultValue={itininfo.packageDayImages} onChange={(event) =>
                                                                    itineraryInputChange(index, 'packageDayImages', event.target.value)} defaultValue={itininfo.packageDayImages}></textarea>
                                                            </div>
                                                        </div>
                                                    </div> <button type="button"
                                                            onClick={() => handleRemovePlayers(index, itininfo.itineraryDetailId)}
                                                        class="btn btn-primary btn-lg " >Delete</button>
                                                    <hr></hr>
                                                </>
                                            )):<></>}
                                        </div>
                                    </div>
                                </div>
                                {/*Step Two*/}


                                {/*Step Three*/}

                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title">Hotel Information </h4>
                                            {allHotels != null &&
                                                allHotels?.length > 0 ? allHotels.map((allHotelsinfo, index) => {
                                                    if (allHotelsinfo.checkInDt != null && allHotelsinfo.checkInDt.length > 10) {
                                                        var ckdt = allHotelsinfo.checkInDt.substring(0, 10);
                                                        ckdt = parseISO(ckdt)
                                                    } else {
                                                        var ckdt = allHotelsinfo.checkInDt;
                                                    }

                                                    if (allHotelsinfo.checkOutDt != null && allHotelsinfo.checkOutDt.length > 10) {
                                                        var ckodt = allHotelsinfo.checkOutDt.substring(0, 10);
                                                        ckodt = parseISO(ckodt)
                                                    } else {
                                                        var ckodt = allHotelsinfo.checkOutDt;
                                                    }
                                                    //ckdt = (ckdt == '') ? '' : parseISO(ckdt);
                                                    //var ckdt = new Date(allHotelsinfo.checkInDt);
                                                    //var ckodt = new Date(allHotelsinfo.checkOutDt);
                                                    console.log('ckdt--', ckodt)
                                                    

                                                    allHotels[index]['packageDetailId'] = packageId

                                                    
                                               return <>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Hotel Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" id="exampleInputHotel" placeholder="Hotel Name" defaultValue={allHotelsinfo.hotelName}
                                                               onChange={(event) =>
                                                                   hotelInputChange(index, event, "hotelName")
                                                                } required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Checkin Date</label>
                                                       <div class="col-sm-9"> <DatePicker showIcon
                                                           utcOffset={0}
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={ckdt}
                                                            onChange={(date) => hotelInputChangeDate(index, date, "checkInDt")}
                                                            required
                                                        /> </div>
                                                   </div>
                                                   <div class="form-group row">
                                                       <label for="exampleInputMobile" class="col-sm-3 col-form-label">Checkout Date</label>
                                                       <div class="col-sm-9"> <DatePicker showIcon
                                                           utcOffset={0}
                                                           className="form-control"
                                                           dateFormat="dd/MM/yyyy"
                                                           selected={ckodt}
                                                           onChange={(date) => hotelInputChangeDate(index, date, "checkOutDt")}
                                                           required
                                                       /> </div>
                                                   </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Room Type </label>
                                                       <div class="col-sm-9">  <input type="text" class="form-control" id="exampleInputPlace" placeholder="Place"
                                                           defaultValue={allHotelsinfo.roomType} onChange={(event) =>
                                                           hotelInputChange(index, event, "roomType")
                                                       } required />  <br />
                                                           
                                                       </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Place</label>
                                                        <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputPlace" placeholder="Place" defaultValue={allHotelsinfo.place} onChange={(event) =>
                                                            hotelInputChange(index, event, "place")
                                                        } required /> </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Number of Rooms</label>
                                                        <div class="col-sm-9"> <input type="number" min="1" class="form-control" id="exampleInputnumroom" placeholder="Number of Rooms" defaultValue={allHotelsinfo.noOfRooms} onChange={(event) =>
                                                            hotelInputChange(index, event, "noOfRooms")
                                                        } required /> </div>
                                                    </div>
                                                   {/* <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Number of Nights</label>
                                                        <div class="col-sm-9"> <input type="number" min="1" class="form-control" id="exampleInputnumnights" placeholder="Number of Nights" defaultValue={allHotelsinfo.noOfNights} onChange={(event) =>
                                                            hotelInputChange(index, event, "noOfNights")
                                                        } required /> </div>
                                                    </div>*/}
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Special Instructions</label>
                                                        <div class="col-sm-9"> <textarea class="form-control" id="specialinst" placeholder="Special Instructions" defaultValue={allHotelsinfo.specialInstructions} onChange={(event) =>
                                                            hotelInputChange(index, event, "specialInstructions")
                                                        } ></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Meal Plan </label>
                                                        <div class="col-sm-9">  <br /><select class="form-control" value={allHotelsinfo.mealPlan} id="exampleSelectmealplan" onChange={(event) =>
                                                            hotelInputChange(index, event, "mealPlan")
                                                        } required>
                                                            <option value="">Select</option>
                                                            <option value="1">No meal</option>
                                                            <option value="2">One Meal (Breakfast)</option>
                                                            <option value="3">Two Meal(Breakfast, Lunch/Dinner)</option>
                                                            <option value="4">All Meal(Breakfast, Lunch & Dinner)</option>
                                                        </select> </div>
                                                    </div>
                                                    <button type="button"
                                                       onClick={() => hotelRemove(index, allHotelsinfo.hotelDetailId)}
                                                        class="btn btn-primary btn-lg " >Delete</button>
                                                    <hr></hr>
                                                </>
                                            }):<></>}

                                            <button type="button"
                                                onClick={() => handleAddHotels()}
                                                class="btn btn-primary btn-lg " >Add Hotel</button>
                                            <hr></hr>

                                        </div>
                                    </div>
                                </div>
                                {/*Step Three*/}

                                {/* Step Four*/}

                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title">Payment Information -  </h4>
                                            {id != null ?
                                                <>




                                                    <p class="card-description"> </p>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" className="col-sm-3 col-form-label">Package Cost</label>
                                                        <div className="col-sm-9">
                                                            <input type="text" ref={packageCostRef} class="form-control" id="exampleInputpckcost" placeholder="Package Cost" defaultValue={packageCost} onChange={(e) => setPackageCost(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Markup %</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" ref={markupPerRef} min="0" class="form-control" id="exampleInputmper" placeholder="Markup %" defaultValue={markupPer} onChange={(e) => setMarkupPer(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputmp" className="col-sm-3 col-form-label">Markup Price</label>
                                                        <div className="col-sm-9">
                                                            <input type="text" ref={markupPriceRef} class="form-control" id="exampleInputpmp" placeholder="Markup Price" defaultValue={markupPrice} onChange={(e) => setMarkupPrice(e.target.value)} readOnly />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputEmail2" className="col-sm-3 col-form-label">Taxable</label>
                                                        <div class="col-sm-9">
                                                            <input type="number" ref={taxableRef} readOnly min="0" class="form-control" id="exampleInputtaxable" placeholder="Taxable" defaultValue={taxable} onChange={(e) => setTaxable(e.target.value)} required />

                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">TCS %</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" ref={tcsPerRef} min="0" class="form-control" id="exampleInputtcsper" placeholder="TCS %" defaultValue={tcsPer} onChange={(e) => setTcsPer(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputmp" className="col-sm-3 col-form-label">TCS Price</label>
                                                        <div className="col-sm-9">
                                                            <input type="text" ref={tcsPriceRef} class="form-control" id="exampleInputtcspri" placeholder="TCS Price" defaultValue={tcsPrice} onChange={(e) => setTcsPrice(e.target.value)} readOnly />
                                                        </div>
                                                    </div>


                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">GST %</label>
                                                        <div class="col-sm-9">

                                                            <select class="form-control" id="exampleSelectPaymentStatus" value={gstper} onChange={(e) => setGstPer(e.target.value)} required>
                                                                <option value="">Select</option>
                                                                <option value="5">5%</option>
                                                                <option value="12">12%</option>
                                                                <option value="18">18%</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label"></label>
                                                        <div class="col-sm-9">
                                                            <button type="button" class="btn btn-gradient-primary me-2" onClick={paymentChange}>Calculate</button>
                                                        </div>


                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputgst" class="col-sm-3 col-form-label">GST Tax</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" ref={gstRef} readOnly min="0" defaultValue={gst} class="form-control" id="exampleInputtgst" placeholder="GST" />

                                                        </div>


                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Total Tax</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" ref={totalTaxRef} readOnly min="0" defaultValue={totalTax} class="form-control" id="exampleInputtax" placeholder="Tax" />

                                                        </div>


                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Final Payable Amount</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" ref={finalCostRef} readOnly min="0" defaultValue={finalCost} class="form-control" id="exampleInputtot" placeholder="Total" />

                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Adult</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" min="0" class="form-control" id="exampleInputadult" placeholder="Adult" defaultValue={noAdult} onChange={(e) => setAdult(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Child</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" min="0" class="form-control" id="exampleInputchild" placeholder="Child" defaultValue={noChild} onChange={(e) => setChild(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Infant</label>
                                                        <div class="col-sm-9">

                                                            <input type="number" min="0" class="form-control" id="exampleInputInfant" placeholder="Infant" defaultValue={noInfant} onChange={(e) => setInfant(e.target.value)} required />
                                                        </div>
                                                    </div>



                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Payment Status </label>
                                                        <div class="col-sm-9">  <br /><select class="form-control" id="exampleSelectPaymentStatus" value={paymentStatusId} onChange={(e) => setPaymentStatusId(e.target.value)} required>
                                                            <option value="">Select</option>
                                                            <option value="1">Paid</option>
                                                            <option value="2">Unpaid</option>

                                                        </select> </div>
                                                    </div>

                                                    <div class="form-check form-check-flat form-check-primary">
                                                        <label class="form-check-label" />

                                                    </div>

                                                    <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>


                                                </>
                                                :
                                                <div class="form-check form-check-flat form-check-primary text-danger">

                                                    {message ? <p>{message}</p> : null}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Step Four*/}
                            </form>
                        </>
                        : <div class="form-check form-check-flat form-check-primary text-danger">

                            {message ? <p>{message}</p> : null}
                        </div>
                    }
                </div>

                

            </Layout>

        </>
    )
}