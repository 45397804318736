import { Link } from 'react-router-dom'
import Layout from '../layout/layout'
import { APICall } from '../utils/utils.js'
import { useState, useEffect, useRef } from 'react'
import { format } from 'date-fns';
import Pagination from '../components/Pagination';
export default function Leads() {

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [leads, setLeads] = useState(null);
    const [cmsg, setCmsg] = useState("");
    const [keyword, setKeyword] = useState('');
  
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [totalRecordsCount, setTotalRecordCount] = useState(0);
    const currentRecords = leads != null ? leads : null;
    const nPages = leads != null ? Math.ceil(totalRecordsCount / recordsPerPage) : 0;

    const leadData = {
        keyword: '',
        pageSize: recordsPerPage,
        PageNumber: currentPage,

    };

    function onPageChange(pageNumber) {

        leadData.PageNumber = pageNumber;
        getLeadlist();
    }

    function resetPaging()
    {
        setCurrentPage(1);
    }
    

    const usernameHandler = (event) => {
        setKeyword(event.target.value)
    }


    const searchRef = useRef();
    useEffect(() => {

        const cmsg = JSON.parse(localStorage.getItem('cmsg'));
        if (cmsg) {
            setCmsg(cmsg);
            localStorage.removeItem('cmsg')
        }

        getLeadlist()


    }, []);





    function getLeadlist() {


        var response = APICall(leadData, 'Leads/GetByCondition', 'POST')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setLeads(resposnse.Result.leads);
                    setTotalRecordCount(resposnse.Result.totalCount);
                    // setLoading(false);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });
    }

    function resetHandler() {
        resetPaging();
        getLeadlist();

    }

    function submitHandler(e) {
        e.preventDefault();
        resetPaging();
        const leadData = {
            keyword: keyword,
            pageSize: recordsPerPage,
            PageNumber: currentPage,

        };

        var response = APICall(leadData, 'Leads/GetByCondition', 'POST')
            .then(resposnse => {
                //console.log('Data', resposnse);
                searchRef.current.value = '';
                if (resposnse.StatusCode === 200) {
                    setLeads(resposnse.Result.leads);
                    setTotalRecordCount(resposnse.Result.totalCount);
                } else {
                    setLeads(null);
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

        console.log('You clicked submit.');
    }



    return (
        <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Leads
                        </h3>
                        <a class="nav-link1" href="/create-lead"><button class="btn btn-primary btn-lg ">Create</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Listing</h4>
                                {cmsg ? <div className="text-success"> {cmsg} </div> : null}
                                <form className="pt-3" onSubmit={submitHandler}>
                                    <input type="text" name="keyword" className="form-control form-control-lg" style={{
                                        display: 'inline-block',
                                        width: 200
                                    }} ref={searchRef} onChange={(e) => setKeyword(e.target.value)} required /> <button type="submit" class="btn btn-primary btn-lg " >Search</button> <button type="button" onClick={resetHandler} class="btn btn-primary btn-lg " >Reset</button>
                                </form>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th> # </th>
                                        <th> Name </th>
                                        <th> Status </th>
                                        <th> Phone </th>
                                        <th>Assign to</th>
                                        <th>Departure Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRecords != null ?
                                        currentRecords?.length > 0 ? currentRecords.map((lead) => (
                                            <tr>
                                                <td> {lead.leadId} </td>
                                                <td> {lead.firstName} {lead.lastName} </td>
                                                <td> <label class="badge badge-info">{lead.leadStatus}</label> </td>
                                                <td> {lead.phone} </td>
                                                <td>{lead.assingedUser} </td>
                                                <td> {format(new Date(lead.departureDate), 'dd/MM/yyyy')} </td>
                                                <td> <a class="nav-link1" href={`/view-lead/${lead.leadId}`} title="View"><i class="mdi mdi-tooltip-text icon-md text-primary"></i></a> <a class="nav-link1" href={`/edit-lead/${lead.leadId}`} title="Edit"><i class="mdi mdi mdi-grease-pencil icon-md text-primary"></i></a> </td>
                                            </tr>
                                        ))
                                            : <tr>
                                                <td colSpan="7" className="text-danger"><h3>No listing available!</h3></td></tr>

                                        : <tr>
                                            <td colSpan="7" className="text-danger"></td></tr>
                                    }

                                </tbody>
                            </table>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                onPageChange={onPageChange}
                             />
                        </div>

                    </div>
                </div>


            </Layout>

        </>
    )
}