import React from "react";



export default function Leftmenu() {


    var roleId = sessionStorage.getItem('roleId');
    var username = sessionStorage.getItem('name');
    var designation = sessionStorage.getItem('designation');

    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className="nav-item nav-profile">
                        <a  className="nav-link">
                           
                            <div className="nav-profile-text d-flex flex-column">
                                <span className="font-weight-bold mb-2">{username} </span>
                                <span className="text-secondary text-small">{designation }</span>
                            </div>

                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/dashboard">
                            <span className="menu-title">Dashboard</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                            <span className="menu-title">Leads Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="/leads">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="/create-lead">Create</a></li>                                
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#it-qu" aria-expanded="false" aria-controls="it-qu">
                            <span className="menu-title">Quotation</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="it-qu">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="/quotes">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="/new-quote">Create</a></li>                                
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#it-cust" aria-expanded="false" aria-controls="it-qu">
                            <span className="menu-title">Customer Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="it-cust">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="/customers">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="/create-customer">Create</a></li>
                            </ul>
                        </div>
                    </li>
                    {roleId == 1 ?
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#it-staff" aria-expanded="false" aria-controls="it-qu">
                            <span className="menu-title">Staff Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="it-staff">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="/staff">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="/create-staff">Create</a></li>
                            </ul>
                        </div>
                       </li>
                     :<></>
                    }
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#it-profile" aria-expanded="false" aria-controls="it-qu">
                            <span className="menu-title">Profile</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="it-profile">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="/profile">Update</a></li>
                                
                            </ul>
                        </div>
                    </li>

                    {/*  <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#oppo" aria-expanded="false" aria-controls="oppo">
                            <span className="menu-title">Opportunity Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="oppo">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#cust" aria-expanded="false" aria-controls="cust">
                            <span className="menu-title">Customer Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="cust">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#it-qu" aria-expanded="false" aria-controls="it-qu">
                            <span className="menu-title">Itinerary & Quatation</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="it-qu">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#invoice" aria-expanded="false" aria-controls="invoice">
                            <span className="menu-title">Invoice Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="invoice">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#msg" aria-expanded="false" aria-controls="msg">
                            <span className="menu-title">Message Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="msg">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#st" aria-expanded="false" aria-controls="st">
                            <span className="menu-title">Support Ticket </span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="st">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Closed</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#staff" aria-expanded="false" aria-controls="staff">
                            <span className="menu-title">Staff Management</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                        </a>
                        <div className="collapse" id="staff">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">List</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Create</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Deleted</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#setting-pages" aria-expanded="false" aria-controls="setting-pages">
                            <span className="menu-title">Settings</span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-brightness-7 menu-icon"></i>
                        </a>
                        <div className="collapse" id="setting-pages">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/blank-page.html"> Email </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> SMS </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Template </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> Currency </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> Block IP </a></li>
                            </ul>
                        </div>
                    </li> */}

                </ul>
            </nav>

        </>
    );
}